function request(url = "", method = "get", data = {}) {
    return $.ajax({
        url: url, type: method, data: data,
    })
}

$(document).ready(function () {
    $(document).on('submit', 'form', function (e) {
        let btn = $(this).find('button');
        if (btn.attr('type') === 'button') {
            e.preventDefault();
            return;
        }
        btn.attr('type','button');
        btn.find("spinner").remove();
        btn.append(' <i class="spinner fa fa-spinner fa-spin"></i>');
    })

    $(document).on("click", ".choose-products label", function () {
        let self = $(this);
        self.closest(".product-select-boxes").find("label").removeClass("active");
        self.addClass("active");
        if (!self.closest(".plans").length) {
            let sub_id = self.closest("div").find("input").val();
            $(".plans > div").hide();
            $(`.plan-${sub_id}`).show();
            $(".plans label").removeClass("active");
            $('.choose-products input').prop('checked', false);
        }
    })

    // send tip message
    $(document).on('click','.send-tip-message', function(){
        let type = $(this).data('type');
        let modal = $(this).data('bs-target');
        $(modal).find("input[name='type']").val(type);
    })

    $(document).on("click", ".tip-send", function () {
        let self = $(this);
        let id = $(this).data('id');
        let form = $("#form_send_tip_message_" + id);
        const formData = form.serialize();
        self.append('<i class="spinner fa fa-spinner fa-spin"></i>');
        self.removeClass("tip-send");

        request("/products/tip", "POST", formData)
            .done(function (response) {
                if (response.success) {
                    location.assign(response.data.url)
                    $('#send_tip_message_' + id).hide();
                } else {
                    if (response.message) {
                        form.find(".error-alarm").text('※' + response.message)
                    } else {
                        form.find(".error-alarm").text();
                    }

                    if (response.data.message) {
                        form.find(".message-box textarea").addClass("is-invalid")
                    } else {
                        form.find(".message-box textarea").removeClass("is-invalid");
                    }

                    if (response.data.amount) {
                        form.find(".amount-box input").addClass("is-invalid")
                    } else {
                        form.find(".amount-box input").removeClass("is-invalid");
                    }

                    if (response.data.product_id) {
                        form.find(".product-box select").addClass("is-invalid")
                    } else {
                        form.find(".product-box select").removeClass("is-invalid");
                    }
                    self.addClass("tip-send");
                    self.find(".spinner").remove();
                }
            })
            .fail(function () {
                alert("Error")
            })
    })

    $(document).on('click', '.card-info .load-form-card', function () {
        $(this).html('<i class="spinner fa fa-spinner fa-spin"></i>');
        request('/user/load-form-add-card')
            .done(function (res) {
                if (res.success) {
                    $('.card-info').html(res.html);
                } else {
                    location.assign('/user/profile/edit-payment-information' + '?redirect_to=' + location.href)
                }
            })
            .fail(function () {
                toastr.error('System error')
            })
    })
})

